import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { ProductState } from '@store/ducks/product';
import { AppThunk, AppThunkAction, RootState } from '@store/ducks/index';
import { APICartItemResponse, APICartItemsResponse } from '@api/types/cart';
import { APICartResponse } from '@src/api/types/cart/cart';
import { ApiMiddleware } from '@vsemayki/shared-frontend';
import Cookies from 'js-cookie';
import { WidgetConfig } from 'pages/constructor';
import { userSelector } from './application/user';
import { isAxiosError } from '@utils/helpers';
import { APIDeliveryValidateResponse } from '@api/types/delivery/validate';
import { geoSelector } from './geo';
import { loadingActions } from '@ducks/application/loading';

const { CALL_API, apiRequest } = ApiMiddleware;

export const CART_COOKIE = 'lite-cart';

export type CartItem = APICartItemResponse['data'];

type CompareItem = Pick<CartItem, 'design' | 'product' | 'attributes'>;
export const comparisonItems = (
    checkItem: CompareItem,
    findItem: CompareItem
) => {
    return (
        checkItem.design === findItem.design &&
        checkItem.product === findItem.product &&
        isEqual(checkItem.attributes, findItem.attributes)
    );
};

export interface CartState {
    bannedDesign: APIDeliveryValidateResponse['additionalData'];
    id: string;
    items: Array<CartItem>;
    promocode: string;
    promocodeRejectMessage: string;
}

const initialState: CartState = {
    bannedDesign: [],
    id: '',
    items: [],
    promocode: '',
    promocodeRejectMessage: '',
};

const cart = createSlice({
    name: 'Cart',
    initialState,
    reducers: {
        addItem: (state, action: PayloadAction<CartItem>) => {
            state.items.push(action.payload);
        },
        updateItem: (state, action: PayloadAction<CartItem>) => {
            const itemIndex = state.items.findIndex((item) =>
                comparisonItems(item, action.payload)
            );
            if (itemIndex === -1) return;
            state.items[itemIndex] = action.payload;
        },
        deleteItem: (state, action: PayloadAction<CartItem>) => {
            const itemIndex = state.items.findIndex((item) =>
                comparisonItems(item, action.payload)
            );
            if (itemIndex === -1) return;
            state.items.splice(itemIndex, 1);
        },
        replaceItems: (state, action: PayloadAction<CartItem[]>) => {
            state.items = action.payload;
        },
        clearCart: (state) => {
            state.items = [];
            state.promocode = '';
        },
        setPromocode: (state, action: PayloadAction<string>) => {
            state.promocode = action.payload;
        },
        setPromocodeRejectMessage: (state, action: PayloadAction<string>) => {
            state.promocodeRejectMessage = action.payload;
        },
        setCart: (state, action: PayloadAction<APICartResponse['data']>) => {
            const cartResponse = action.payload;
            state.id = cartResponse.id;
            state.promocode = cartResponse.promocode;
        },
        deleteCart: () => initialState,
        setBannedDesigns: (
            state,
            action: PayloadAction<CartState['bannedDesign']>
        ) => {
            state.bannedDesign = action.payload;
        },
    },
});

export const cartActions = cart.actions;
export default cart.reducer;

export const cartSelector = (state: RootState) => state.cart;

const handleCartError = (error: unknown): AppThunk<Promise<void>> => async (
    dispatch
) => {
    if (!isAxiosError(error)) {
        return console.error(
            'CartDuck::handleCartError-> not axios error',
            error
        );
    }

    const status = error.response?.status;

    switch (status) {
        // case 401:
        //     dispatch(logOut());
        //     break;
        case 404:
            dispatch(deleteCart());
            break;
        default:
            console.error(
                'CartDuck::handleCartError-> not handled status code',
                status
            );
    }
};

export const createCart: () => AppThunk<Promise<void>> = () => async (
    dispatch,
    getState
) => {
    try {
        const State = getState();
        const CartState = cartSelector(State);
        const UserState = userSelector(State);

        const cartCookie = Cookies.get(CART_COOKIE);
        if (!process.browser || cartCookie) return;

        const { data: newCart } = await dispatch<APICartResponse>({
            type: CALL_API,
            payload: {
                url: 'rest/cart',
                method: 'POST',
                data: { promocode: CartState.promocode, user_id: UserState.id },
            },
        });

        Cookies.set(CART_COOKIE, newCart.id, {
            expires: 365,
        });
        dispatch(cartActions.setCart(newCart));
    } catch (error) {
        console.error('cant create cart', error);
    }
};

export const getCart: () => AppThunk<Promise<void>> = () => async (
    dispatch,
    getState
) => {
    try {
        const State = getState();
        const CartState = cartSelector(State);
        const cartCookie = Cookies.get(CART_COOKIE);
        if (CartState.id || !process.browser) return;
        if (!cartCookie) return console.warn('cart cookie not found');

        const { data: cart } = await dispatch<APICartResponse>({
            type: CALL_API,
            payload: {
                url: `rest/cart/${cartCookie}`,
            },
        });
        dispatch(cartActions.setCart(cart));
    } catch (error) {
        console.error('cant get cart, create new one', error);
        await dispatch(handleCartError(error));
        await dispatch(createCart());
    }
};

export const getOrCreateCart: () => AppThunk<Promise<void>> = () => async (
    dispatch,
    getState
) => {
    try {
        const State = getState();
        const CartState = cartSelector(State);
        const cartCookie = Cookies.get(CART_COOKIE);
        if (CartState.id || !process.browser) return;
        if (cartCookie) {
            await dispatch(getCart());
        } else {
            await dispatch(createCart());
        }
    } catch (error) {
        console.error('cant get or create cart', error);
        await dispatch(handleCartError(error));
    }
};

export const getCartItems: () => AppThunk<Promise<void>> = () => async (
    dispatch,
    getState
) => {
    try {
        const State = getState();
        const CartState = cartSelector(State);
        const CartId = CartState.id || Cookies.get(CART_COOKIE);

        if (!CartId) return console.warn('CartId not found');

        const { data: cartItems } = await dispatch<APICartItemsResponse>({
            type: CALL_API,
            payload: { url: `rest/cart/${CartId}/item` },
        });

        dispatch(
            cartActions.replaceItems(
                cartItems.map<CartItem>((item) => ({
                    ...item,
                    // TODO: refactor backend?
                    price: parseInt(item.price),
                    design: parseInt(item.design),
                }))
            )
        );
        await dispatch(validateDesigns());
    } catch (error) {
        console.error('cant get cart items', error);
        await dispatch(handleCartError(error));
    }
};

export const addToCart: (article: ProductState) => AppThunk<Promise<void>> = (
    product
) => async (dispatch, getState) => {
    try {
        const State = getState();
        const CartState = cartSelector(State);
        if (!product.data || !product.activeVariant) return;

        if (!CartState.id) {
            await dispatch(getOrCreateCart());
            return dispatch(addToCart(product));
        }

        const attributes: Partial<CartItem['attributes']> = {
            color: product.activeVariant.info.color.alias,
            size: product.activeVariant.info.size.alias,
            type: 'p',
        };

        try {
            await dispatch<APICartItemResponse>({
                type: CALL_API,
                payload: {
                    url: `rest/cart/${CartState.id}/item`,
                    method: 'POST',
                    data: {
                        amount: 1,
                        attributes,
                        design: product.data.design.id,
                        price: product.activeVariant.price,
                        product: product.data.category.id,
                    },
                },
            });
        } catch (error) {
            if (isAxiosError(error)) {
                await dispatch(handleCartError(error));
            }

            await dispatch(createCart());
            return dispatch(addToCart(product));
        }

        dispatch(getCartItems());
    } catch (error) {
        console.error('cant add item to cart', product, error);
        await dispatch(handleCartError(error));
    }
};

export const addToCartFromConstructor: AppThunkAction<
    Parameters<WidgetConfig['toCartCallback']>[0]
> = (product) => async (dispatch, getState) => {
    try {
        const State = getState();
        const CartState = cartSelector(State);
        if (!product) return;

        if (!CartState.id) {
            await dispatch(getOrCreateCart());
            return dispatch(addToCartFromConstructor(product));
        }

        const attributes: Partial<CartItem['attributes']> = {
            color: product.attributes.color ?? '',
            size: product.attributes.size ?? '',
            type: 'c',
        };

        await dispatch<APICartItemResponse>({
            type: CALL_API,
            payload: {
                url: `rest/cart/${CartState.id}/item`,
                method: 'POST',
                data: {
                    amount: 1,
                    attributes,
                    design: product.designId,
                    price: 0,
                    product: product.productType,
                },
            },
        });

        await dispatch(getCartItems());
    } catch (error) {
        console.error('cant add item to cart', product, error);
    }
};

export const updateInCart: AppThunkAction<CartItem> = (item) => async (
    dispatch,
    getState
) => {
    try {
        const State = getState();
        const CartState = cartSelector(State);
        if (!item.id)
            return console.error('cant update item, id not found', item);
        if (!CartState.id) {
            await dispatch(getOrCreateCart());
            return dispatch(updateInCart(item));
        }

        await dispatch<APICartItemResponse>({
            type: CALL_API,
            payload: {
                url: `rest/cart/${CartState.id}/item/${item.id}`,
                method: 'PUT',
                data: item,
            },
        });
        dispatch(getCartItems());
    } catch (error) {
        console.error('cant update item from cart', item, error);
    }
};

export const updateInCartFromConstructor: AppThunkAction<
    Parameters<WidgetConfig['updateCartCallback']>[0]
> = (item) => async (dispatch, getState) => {
    try {
        const State = getState();
        const CartState = cartSelector(State);

        if (!item) return console.error('cant update item, id not found', item);

        if (!CartState.id) {
            await dispatch(getOrCreateCart());
            return dispatch(updateInCartFromConstructor(item));
        }

        const cartItem = CartState.items.find(
            (cartItem) => cartItem.design === parseInt(item.oldDesignId)
        );

        if (!cartItem) {
            console.warn(
                `cant find item with design: ${item.oldDesignId} create new one`
            );
            return await dispatch(addToCartFromConstructor(item));
        }

        const updatedItem = {
            ...item,
            design: item.designId,
            attributes: { ...item.attributes, type: 'c' },
        };

        await dispatch<APICartItemResponse>({
            type: CALL_API,
            payload: {
                url: `rest/cart/${CartState.id}/item/${cartItem.id}`,
                method: 'PUT',
                data: updatedItem,
            },
        });

        await dispatch(getCartItems());
    } catch (error) {
        console.error('cant update item from cart', item, error);
    }
};

export const deleteFromCart: (item: CartItem) => AppThunk<Promise<void>> = (
    item
) => async (dispatch, getState) => {
    try {
        const State = getState();
        const CartState = cartSelector(State);
        if (!item.id)
            return console.error('cant delete item, id not found', item);
        if (!CartState.id) {
            await dispatch(getOrCreateCart());
            return dispatch(deleteFromCart(item));
        }

        await dispatch<{
            data: 'string';
            message: 'string';
            success: boolean;
        }>({
            type: CALL_API,
            payload: {
                url: `rest/cart/${CartState.id}/item/${item.id}`,
                method: 'DELETE',
            },
        });
        dispatch(getCartItems());
    } catch (error) {
        console.error('cant delete item from cart', item, error);
    }
};

export const deleteCart: AppThunkAction = () => async (dispatch, getState) => {
    const State = getState();
    const CartState = cartSelector(State);

    const cartId = CartState.id || Cookies.get(CART_COOKIE);
    if (!cartId) {
        return;
    }

    Cookies.remove(CART_COOKIE);

    try {
        // eslint-disable-next-line @typescript-eslint/ban-types
        await dispatch<{}>(
            apiRequest({ method: 'DELETE', url: `/rest/cart/${cartId}` })
        );
    } catch (e) {
        return console.warn('Error when delete cart', e);
    }

    dispatch(cartActions.deleteCart());
};

export const validateDesigns: AppThunkAction = () => async (
    dispatch,
    getState
) => {
    try {
        const State = getState();
        const cart = cartSelector(State);
        const country = geoSelector(State).country;

        const body = {
            items: cart.items.map((item) => ({
                template_id: item.design,
            })),
            delivery: {
                address: {
                    country: country.title,
                },
            },
        };

        const res = await dispatch<APIDeliveryValidateResponse>(
            apiRequest({
                method: 'POST',
                url: '/rest/proxy-delivery/validate',
                data: body,
            })
        );

        const validDesigns = Array.isArray(res);

        const bannedDesigns = (!Array.isArray(res) && res.additionalData) || [];

        dispatch(
            cartActions.setBannedDesigns(validDesigns ? [] : bannedDesigns)
        );
    } catch (error) {
        console.error('CartDuck::validateDesigns-> not valid response', error);
    }
};

export const setPromocode: AppThunkAction<string, Promise<void>> = (
    promocode: string
) => async (dispatch, getState) => {
    const State = getState();
    const CartState = cartSelector(State);

    const cartId = CartState.id || Cookies.get(CART_COOKIE);
    if (!cartId) {
        return;
    }

    dispatch(loadingActions.setLoading(true));

    try {
        await dispatch(
            apiRequest({
                method: 'PUT',
                url: `/rest/cart/${cartId}`,
                data: {
                    promocode: promocode || '',
                },
            })
        );

        dispatch(cartActions.setPromocode(promocode));
    } catch (e) {
        return console.warn('Error when apply promo code', e);
    } finally {
        dispatch(loadingActions.setLoading(false));
    }
};

export const validatePromocode: AppThunkAction<
    string,
    Promise<{
        reject: boolean;
        apply?: boolean;
        description?: string;
        message?: string;
    }>
> = (promocode: string) => async (dispatch, getState) => {
    const State = getState();
    const CartState = cartSelector(State);

    try {
        dispatch(loadingActions.setLoading(true));

        const payload = {
            cart: CartState.items.map((item) => {
                return {
                    article: item.design,
                    color_tkey: item.attributes.color,
                    price: item.price,
                    product_tkey: item.product,
                    qty: item.amount,
                    size_tkey: item.attributes.size,
                    type: item.attributes.type || 'p',
                };
            }),
            promocode,
        };

        const {
            promocodes,
            discountRejectReasons,
            promocode_description,
        } = await dispatch(
            apiRequest({
                method: 'POST',
                url: `/rest/order/apply-discount`,
                data: payload,
            })
        );

        const rejectCodes = [
            'date_expired',
            'code_not_found',
            'already_in_use',
        ];

        if (get(promocode_description, 'is_certificate', 0)) {
            return {
                apply: false,
                reject: true,
                message: 'Введен подарочный сертификат',
                description: '',
            };
        }

        return {
            apply: promocodes,
            reject: discountRejectReasons
                ? // @ts-ignore
                  discountRejectReasons.promocode.some(({ reason }) =>
                      rejectCodes.includes(reason)
                  )
                : false,
            message: get(discountRejectReasons, 'promocode.0.message', ''),
            description: get(promocode_description, 'text', ''),
        };
    } catch (e) {
        return {
            reject: true,
        };
    } finally {
        dispatch(loadingActions.setLoading(false));
    }
};

export const setPromocodeForCart: AppThunkAction<string, Promise<void>> = (
    promocode: string
) => async (dispatch, getState) => {
    const State = getState();
    const CartState = cartSelector(State);

    const cartId = CartState.id || Cookies.get(CART_COOKIE);

    try {
        dispatch(loadingActions.setLoading(true));

        await dispatch(
            apiRequest({
                method: 'PUT',
                url: `/rest/cart/${cartId}`,
                data: {
                    promocode,
                },
            })
        );

        dispatch(cartActions.setPromocode(promocode));
    } catch (e) {
        console.warn('Error when apply promo code', e);
    } finally {
        dispatch(loadingActions.setLoading(false));
    }
};
