import { AxiosError } from 'axios';
const get = require('lodash/get');
export const pickTyped = <T, K extends keyof T>(
    obj: T,
    ...keys: K[]
): Pick<T, K> => {
    const ret = {} as Pick<T, K>;
    keys.forEach((key) => {
        ret[key] = obj[key];
    });
    return ret;
};

export const createElementFromHTMLClientSide = (htmlString = '') => {
    if (!process.browser) return;

    const template = document.createElement('template');
    template.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes
    return template.content.childNodes;
};

export const isStringEqualRussia = (str: string) =>
    str.toLocaleLowerCase() === 'россия';

export const isAxiosError = (error: unknown): error is AxiosError =>
    (error as AxiosError) && (error as AxiosError).isAxiosError;

export const mapSides = (sides: any, mainSide: number) => {
    const images = [];
    const frontSideIndex = mainSide === 2 ? 1 : mainSide;
    const front = get(sides, `[${frontSideIndex}]`);
    const back = get(sides, `[${mainSide + 2}]`);
    images.push(front);
    if (back) images.push(back);
    return images;
};

export const findParent = (child: { parent: any }, structure: any[]) => {
    child = structure.find(({ id }) => child === id);
    if (!child) {
        return false;
    }
    let parent;
    do {
        parent = structure.find(({ id }) => child.parent === id);
        if (!parent) return child;
        child = parent;
    } while (true);
};
